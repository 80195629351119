import { get, post } from "@/utils/axios";

export async function getUserFieldConfig(userEntity, userGuid) {
  let response = await post("/fieldconfig/PersonFieldConfigList", {
    entityType: userEntity,
    entityGuid: userGuid
  });
  return response.map(res => {
    res.valueRange = res.valueRange ? JSON.parse(res.valueRange) : [];
    return res;
  });
}

export async function getTestGroupFieldConfig(testGroupGuid) {
  let response = await get(
    `/fieldconfig/GroupPersonFieldConfigList/${testGroupGuid}`
  );
  return response.map(res => {
    res.valueRange = res.valueRange ? JSON.parse(res.valueRange) : [];
    return res;
  });
}

export function getAllFieldConfigRules(userEntity, userGuid) {
  return post("/fieldconfig/AllFieldConfigRulesList", {
    entityType: userEntity,
    entityGuid: userGuid
  });
}

export function getAllGroupFieldConfigRules(testGroupGuid) {
  return get(`/fieldconfig/AllGroupFieldConfigRulesList/${testGroupGuid}`);
}

export async function updateUserFieldConfig(updatePropList, newConfig) {
  await post("/fieldconfig/UpdateCustomerPersonFieldConfig", {
    updatePropList,
    newConfig
  });
}

export async function updateTestGroupFieldConfig(
  testGroupGuid,
  updatePropList,
  newConfig
) {
  await post("/fieldconfig/UpdateTestGroupPersonFieldConfig", {
    testGroupGuid,
    updatePropList,
    newConfig
  });
}
